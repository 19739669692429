import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import { HandySvg } from 'handy-svg';
import BottomSheet from 'react-draggable-bottom-sheet';
import { useTranslation, Trans } from 'react-i18next';
import IconStar from '@/icons/star.svg'
import { useState } from 'react';
import IconClose from "@/icons/close.svg";
import IconSpinner from "@/icons/spinner.svg";
import { useSelector } from 'react-redux';
import { getUser } from '@/modules/User/reducer';
import { getRaffle } from '@/features/Raffle/reducer';
import { bgColorsArray } from '@/constants';

export default function RaffleOther() {
	const raffle = useSelector(getRaffle)
	const user = useSelector(getUser)

	const WebApp = useWebApp();
	const { t } = useTranslation()

	const [isSubOpen, setIsSubOpen] = useState(false);
	const subClose = () => setIsSubOpen(false);

	const [page, setPage] = useState(0);
	const [load, setLoad] = useState(false);
	const [pay, setPay] = useState(false);

	return (
		<>
			<div className='boxList_item boxList_item--subscription' onClick={() => setIsSubOpen(true)}>
				<div className='boxList_item-icon boxList_item-icon--svg'>
					<HandySvg src={IconStar} />
				</div>
				<div className='boxList_item-content'>
					<div className='boxList_item-title'>{ t('features.raffle.other.open_btn.title') }</div>
					<div className='boxList_item-text'>{ t('features.raffle.other.open_btn.text') }</div>
				</div>
			</div>
			<BottomSheet isOpen={isSubOpen} close={subClose} disabled>
				<button className='BottomSheetClose' onClick={subClose}><HandySvg src={IconClose}/></button>
				<div className='BottomSheetTitle'>{t('features.raffle.other.title')}</div>

				{raffle.raffles.length > 0 ?
					<div style={{
						position: 'relative'
					}}>
						<div className='boxList'>
							{!load ?
								raffle.raffles[page].map((r, index) =>
									<a href={r.url} className='boxList_item' key={index} style={{height: 'auto'}}>
										{r.image ?
												<div className='boxList_item-icon boxList_item-icon--square'>
													<img src={r.image} alt='' />
												</div>
											:
												<div className='boxList_item-icon boxList_item-icon--square'>
													<span style={{backgroundColor: bgColorsArray[Math.floor(Math.random()*bgColorsArray.length)]}}>{r.title.substring(0, 1)}</span>
												</div>
											}
										<div className='boxList_item-content'>
											<div className='boxList_item-title' style={{
												whiteSpace: 'wrap'
											}}>{r.title}</div>
											<div className='boxList_item-hint' style={{
												fontSize: '12px',
											}}>{ t('features.raffle.other.end') } {r.date_end}</div>
										</div>
										<div className='boxList_item-right'>
											<div className='btn btn--sm'>{ t('features.raffle.other.participate') }</div>
										</div>
									</a>
								)
							:
								<div style={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									height: '154px'
								}}>
									<HandySvg src={IconSpinner} style={{width: '48px'}} />
								</div>
							}
						</div>
						<div className='boxText'>
							<button
								className='btn btn--block btn--light btn--tt-none'
								onClick={() => {
									if (load) return;
									let p = page + 1;
									if (p > 2 && !user.has_subs) {
										setPay(true)
										return;
									}
									if (!raffle.raffles[p]) p = 0;
									setLoad(true)
									setTimeout(() => {
										setPage(p)
										setLoad(false)
									}, 500)
								}}
							>{ t('features.raffle.other.more') }</button>
						</div>
						<div style={{
							display: pay ? 'flex' : 'none',
							flexDirection: 'column',
							justifyContent: 'flex-end',
							alignItems: 'center',
							position: 'absolute',
							top: 0,
							left: 0,
							bottom: 0,
							right: 0,
							background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.9) 30%, rgba(255, 255, 255, 1) 100%)',
							padding: '25px',
							zIndex: 50,
							textAlign: 'center',
							borderRadius: '10px'
						}}>
							<p>Оформите подписку и получите доступ ко всем розыгрышам!</p>
							<button className='btn btn--tt-none' onClick={() => WebApp.openTelegramLink(process.env.REACT_APP_LINK_TO_BOT + '?start=subscription-clear')}>Купить подписку</button>
						</div>
					</div>
				: null }
				{user.has_subs ? null :
					<div className='boxText'>
						<div className='boxText_body boxText_body--subscription'>
							<img src={process.env.PUBLIC_URL + '/img/gifts.png'} alt='' />
							<Trans i18nKey="features.raffle.other.text" components={{ p: <p />, b: <b /> }} />
						</div>
						<button className='btn btn--tt-none btn--block' onClick={() => WebApp.openTelegramLink(process.env.REACT_APP_LINK_TO_BOT + '?start=subscription-clear')}>
							{ t('features.raffle.other.action_btn') }
						</button>
					</div>
				}
				<div className='boxText'>
					<div className='boxText_body'>
						{ t('features.raffle.other.text_channel') }
					</div>
					<div className='boxList'>
						<a href={process.env.REACT_APP_CHANNEL_LINK} className='boxList_item'>
							<div className='boxList_item-icon'>
								<img src={process.env.PUBLIC_URL + '/img/gs-channel.jpg'} alt='' />
							</div>
							<div className='boxList_item-content'>
								<div className='boxList_item-title'>Розыгрыши от GiveShareBot</div>
							</div>
							<div className='boxList_item-right'>
								<div className='btn btn--sm'>{ t('features.raffle.content.go') }</div>
							</div>
						</a>
					</div>
				</div>
			</BottomSheet>
		</>
	)
}

