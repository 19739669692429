import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '@/modules/User/reducer';
import { setRaffle, getRaffle } from '@/features/Raffle/reducer';
import AnimationSuccess from '@/icons/animations/Success.json'
import Lottie from 'lottie-react';
import Countdown from 'react-countdown';
import { useState } from 'react';
import { HandySvg } from 'handy-svg';
import IconPlus from '@/icons/plus.svg'
import IconAddUser from '@/icons/add-user.svg'
import WordDeclension from '@/utils/WordDeclension';
import { raffleStatusPreend } from '@/constants';
import BottomSheet from "react-draggable-bottom-sheet";
import copy from 'copy-to-clipboard';
import { useHapticFeedback, useWebApp } from '@vkruglikov/react-telegram-web-app';
import Alert from '@/components/Alert';
import { animationSizes } from '@/constants'
import AnimationConfetti from '@/icons/animations/Confetti.json'
import MemberShareBtn from './ShareBtn';
import { useTranslation, Trans } from 'react-i18next';
import IconClose from "@/icons/close.svg";
import { Link } from 'react-router-dom';
import RaffleOther from '../../../features/Raffle/components/other';
import useEnvInitData from '@/hooks/useEnvInitData'

export default function MemberActive(props) {
	const { t } = useTranslation()
	const raffle = useSelector(getRaffle)
	const user = useSelector(getUser)
	const [boxTicketsOpened, setBoxTicketsOpened] = useState(false)
	const dispatch = useDispatch()
	const [impactOccurred, notificationOccurred] = useHapticFeedback();

	const ended = () => {
		notificationOccurred('success')
		dispatch(setRaffle({
			...raffle,
			status: raffleStatusPreend
		}))
	}

	const [copied, setCopied] = useState(false);
	const [isRefOpen, setIsRefOpen] = useState(false);
	const refClose = () => setIsRefOpen(false);

	const handleCopy = () => {
		copy(user.ref_url)
		if (copied) return false;
		impactOccurred('medium')
		setCopied(true)
		setTimeout(() => {
			setCopied(false)
		}, 3000)
	}

	const [InitDataUnsafe, InitData] = useEnvInitData();
	const [isRefStoryOpen, setIsRefStoryOpen] = useState(false);
	const refStoryClose = () => setIsRefStoryOpen(false);
	const WebApp = useWebApp();

	return (
		<>
			<div className='boxIntro'>
				{user.new && (
					<div className='boxIntro_bgAnimation'>
						<Lottie animationData={AnimationConfetti} loop={false} />
					</div>
				)}
				<div className='boxIntro_sticker'>
					<Lottie animationData={AnimationSuccess} loop={false} style={animationSizes['AnimationSuccess']} />
				</div>
				<div className='boxIntro_title'>
					{user.new ?
						<Trans i18nKey="modules.member.active.title.new" components={{ b: <b /> }} values={{ ticket: user.tickets[0].ticket }} />
					:
						t('modules.member.active.title.done')
					}
				</div>
				<div className='boxIntro_text'>
					{user.new &&
						<Trans i18nKey="modules.member.active.text.start" components={{ b: <b />, br: <br /> }} values={{ title: raffle.title }} />
					}
					{ t('modules.member.active.text.end') }
				</div>
				<div className='boxIntro_countdown'>
					<Countdown
						date={raffle.date_end_count}
						renderer={(props) => {
							if (props.completed) {
								ended()
								return t('modules.member.active.ended')
							} else {
								return (
									<span className='boxIntro_countdown-timer'>
										{props.days > 0 &&
											<>
												{props.days}<WordDeclension code='modules.member.active.days' count={props.days} />,
											</>
										}
										{' ' + props.formatted.hours}
										:{props.formatted.minutes}
										:{props.formatted.seconds}
									</span>
								)
							}
						}}
					/>
					<div className='boxIntro_countdown-text'>{ t('modules.member.active.toEnd') }</div>
				</div>
				<button className='btn btn--block btn--light btn--tt-none' onClick={props.openRaffle}>{ t('modules.member.active.aboutRaffle') }</button>
				{user.is_creator ?
					<Link className='btn btn--block btn--tt-none' to='/settings'>
						{ t('features.settings.link') }
					</Link>
				: null }
			</div>

			<div className='boxList'>
				{raffle.referral && user.ref_url ?
					<div className='boxList_item' onClick={() => setIsRefOpen(true)}>
						<div className='boxList_item-icon boxList_item-icon--bg'>
							<HandySvg src={IconAddUser} />
						</div>
						<div className='boxList_item-content'>
							<div className='boxList_item-title'>{ t('modules.member.active.inviteBtn.title') }</div>
							<div className='boxList_item-text'>{ t('modules.member.active.inviteBtn.text') }</div>
						</div>
					</div>
				: null }

				{raffle.referral && user.ref_story ?
					<div className='boxList_item' onClick={() => setIsRefStoryOpen(true)}>
						<div className='boxList_item-icon boxList_item-icon--bg'>
							<HandySvg src={IconPlus} />
						</div>
						<div className='boxList_item-content'>
							<div className='boxList_item-title'>{ t('modules.member.active.storyBtn.title') }</div>
							<div className='boxList_item-text'>{ t('modules.member.active.storyBtn.text') }</div>
						</div>
					</div>
				: null }

				{!raffle.vip ?
					<RaffleOther/>
				: null }
			</div>

			<div className='boxTitle'>{ t('modules.member.active.ticket.title') }</div>
			<div className='boxList'>
				{user.tickets.slice(0, 1).map((ticket) =>
					<div className='boxList_item' key={ticket.ticket}>
						<div className='boxList_item-icon boxList_item-icon--ticket'>
							<img src={process.env.PUBLIC_URL + '/img/gold-ticket.png'} alt='' />
						</div>
						<div className='boxList_item-content'>
							<div className='boxList_item-title'>{ t('modules.member.active.ticket.name') } <b>{ticket.ticket}</b></div>
							<div className='boxList_item-hint'>{ t('modules.member.active.ticket.date') } <b>{ticket.created}</b></div>
						</div>
					</div>
				)}

				{user.tickets.length === 2 ?
					user.tickets.slice(1).map((ticket) =>
						<div className='boxList_item' key={ticket.ticket}>
							<div className='boxList_item-icon boxList_item-icon--ticket'>
								<img src={process.env.PUBLIC_URL + '/img/gold-ticket.png'} alt='' />
							</div>
							<div className='boxList_item-content'>
								<div className='boxList_item-title'>{ t('modules.member.active.ticket.name') } <b>{ticket.ticket}</b></div>
								<div className='boxList_item-hint'>{ t('modules.member.active.ticket.date') } <b>{ticket.created}</b></div>
							</div>
						</div>
					)
				: user.tickets.length > 2 ?
					<div className={'boxList_box boxList_box--without-header' + (boxTicketsOpened ? ' boxList_box--opened' : '')}>
						<button className='boxList_item boxList_box-open' onClick={() => setBoxTicketsOpened(true)}>
							<div className='boxList_item-icon boxList_item-icon--ticket'>
								<img src={process.env.PUBLIC_URL + '/img/gold-ticket.png'} alt='' />
							</div>
							<div className='boxList_item-content'>
								<div className='boxList_item-title'>{ t('modules.member.active.ticket.all') }</div>
							</div>
						</button>

						<div className='boxList_box-body'>
							{user.tickets.slice(1).map((ticket) =>
								<div className='boxList_item' key={ticket.ticket}>
									<div className='boxList_item-icon boxList_item-icon--ticket'>
										<img src={process.env.PUBLIC_URL + '/img/gold-ticket.png'} alt='' />
									</div>
									<div className='boxList_item-content'>
										<div className='boxList_item-title'>{ t('modules.member.active.ticket.name') } <b>{ticket.ticket}</b></div>
										<div className='boxList_item-hint'>{ t('modules.member.active.ticket.date') } <b>{ticket.created}</b></div>
									</div>
								</div>
							)}
						</div>
					</div>
				:
					null
				}
			</div>

			{raffle.referral && user.ref_url ?
				<BottomSheet isOpen={isRefOpen} close={refClose} disabled>
					<button className='BottomSheetClose' onClick={refClose}><HandySvg src={IconClose}/></button>
					<div className='boxText'>
						<div className='boxText_body'>
							<b>{ t('modules.member.active.invite.textStart') }</b>
							<br/><br/>
							<Trans i18nKey="modules.member.active.invite.textEnd" values={{ count: t('modules.member.active.invite.counts.' + raffle.referral) }} />
						</div>
					</div>
					<div className='boxText'>
						<div className='boxText_header'>{ t('modules.member.active.invite.link') }</div>
						<div className='boxText_body'>
							<div className='boxLink' onClick={handleCopy}>
								{!copied ? user.ref_url : t('modules.member.active.invite.copied')}
							</div>
							<div className='boxGrid'>
								<button className='btn btn--tt-none' onClick={handleCopy}>
									{!copied ? t('modules.member.active.invite.copy') : t('modules.member.active.invite.copied')}
								</button>
								<MemberShareBtn raffle={raffle.id} />
							</div>
						</div>
					</div>
					<div className='boxTitle'>{ t('modules.member.active.invite.stat.title') }</div>
					<div className='boxStat'>
						<div className='boxStatItem'>
							<b>{user.stat_user}</b>
							<span>{ t('modules.member.active.invite.stat.users') }</span>
						</div>
						<div className='boxStatItem'>
							<b>{user.stat_member}</b>
							<span>{ t('modules.member.active.invite.stat.members') }</span>
						</div>
					</div>
				</BottomSheet>
			: null }

			{raffle.referral && user.ref_story ?
				<BottomSheet isOpen={isRefStoryOpen} close={refStoryClose} disabled>
					<button className='BottomSheetClose' onClick={refStoryClose}><HandySvg src={IconClose}/></button>
					<div className='boxText'>
						<div className='boxText_body'>
							<b>{ t('modules.member.active.story.textStart') }</b>
							<br/><br/>
							{ t('modules.member.active.story.textEnd') }
						</div>
					</div>
					<button className='btn btn--block btn--tt-none' onClick={() => {
						WebApp.shareToStory(user.ref_story.image, {
							text: InitDataUnsafe.user.is_premium ? raffle.text_native.substr(0, 2048) : t('modules.member.active.story.copyLink') + ' ' + user.ref_story.url,
							widget_link: {
								url: user.ref_story.url,
								name: t('modules.member.active.story.btn_name')
							}
						})
					}}>{ t('modules.member.active.story.btn') }</button>
				</BottomSheet>
			: null }

			{copied && <Alert>{ t('modules.member.active.invite.alert') }</Alert>}
		</>
	)
}
